'use client';

import { useParams, useRouter } from 'next/navigation';
import { useEffect } from 'react';

const RootRedirect = () => {
  const { push } = useRouter();
  const params = useParams<{ videoCode: string }>();

  useEffect(() => {
    push(`/rooms/${params?.videoCode}`);
  }, [params]);
};

export default RootRedirect;
